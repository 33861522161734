@font-face {
  font-family: 'Gmarket Sans';
  src: url('./fonts/GmarketSansTTFLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gmarket Sans';
  src: url('./fonts/GmarketSansTTFMedium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gmarket Sans';
  src: url('./fonts/GmarketSansTTFBold.ttf') format('truetype');
  font-weight: 700;
}
